import React from "react";
// import { Link } from "gatsby";

// import styles from "./success.module.css";
import Layout from "../components/Layout";

function Success() {
  return (
    <Layout>
      <h1>
        Hvala Vam na upitu!{" "}
        <span role="img" aria-label="party popper">
          🎉
        </span>
      </h1>
    </Layout>
  );
}

export default Success;
